import { fetchPageData, PageData } from "../../core/sanityAPI/fetchers";
import { PageSchema } from "@/schema";
import { GetStaticProps } from "next";
import React from "react";
import { renderBlock } from "../blocks/mapping";

const HomePage = (props: PageData<PageSchema>) => {
    return (
        <>
            {props.body.blocks?.map((block: any, index: number | undefined) => {
                return renderBlock(block, index);
            })}
        </>
    );
};

export const getStaticProps: GetStaticProps = async (context) => {
    const data = await fetchPageData('home', false);

    if (!data) {
        return {
            notFound: true,
        };
    }

    return {
        props: data,
    };
};

export default HomePage;
